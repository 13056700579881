<template>
    <div id="front-master">          
        <router-view></router-view>
    </div>
</template>

<script>
export default {
    
};
</script>
